/**
 * @vip模块
 * 2023-04-03 kadiam
*/
// import bossApi from '../utils/bossApi'
import bossXypq from '../utils/bossXypq'
// 商城订单-弹窗通知
export function vipOrderPopUpResDto(data) {
  return bossXypq({ url: 'hx.baoxian/mallOrder/vipOrderPopUpResDto', method: 'POST', data })
}
// 用户中心-首页弹窗提示
export function homePopup(data) {
  return bossXypq({ url: 'hx.baoxian/user/center/vip/homePopup', method: 'GET' })
}
// 用户中心-首页弹窗提示间隔15天
export function homePopupDays(data) {
  return bossXypq({ url: 'hx.baoxian/user/center/vip/homePopupDays', method: 'GET' })
}
// 用户中心-VIP降级弹窗
export function vipPopUpDowngrading(data) {
  return bossXypq({ url: 'hx.baoxian/user/center/vip/vipPopUpDowngrading', method: 'GET' })
}
// 用户中心-VIP滑动规则
export function vipSlide(data) {
  return bossXypq({ url: 'hx.baoxian/user/center/vip/slide', method: 'GET' })
}
// VIP等级列表
export function vipLevelAll(data) {
  return bossXypq({ url: 'hx.baoxian/user/center/vipLevelAll', method: 'GET' })
}
// 用户中心-会员中心
export function memberCenter(data) {
  return bossXypq({ url: 'hx.baoxian/user/center/vip/memberCenter', method: 'GET' })
}
// 用户中心-会员信息
export function member(data) {
  return bossXypq({ url: 'hx.baoxian/user/center/vip/member', method: 'GET' })
}
// 用户中心-自动VIP领取
export function receiveAutomatic(data) {
  return bossXypq({ url: 'hx.baoxian/user/center/vip/receiveAutomatic', method: 'POST' })
}
// 用户中心-验证码VIP领取
export function vip_receiveCode(data) {
  return bossXypq({ url: 'hx.baoxian/user/center/vip/receiveCode', method: 'POST', data })
}
// 商城会员商品列表
export function mallVipProduct_list(data) {
  return bossXypq({ url: 'hx.baoxian/mallVipProduct/list', method: 'POST', data })
}
// 根据用户ID获取商城会员限领次数
export function mallVipLimit_detail(data) {
  return bossXypq({ url: 'hx.baoxian/mallVipLimit/detail', method: 'POST', data })
}
