<template>
  <div class="page" style="margin:0;background: rgb(247, 247, 247);">
    <van-skeleton title :row="23" :loading="loading">
      <!-- 顶部 card-->
      <div class="bg_top_linear-gradient vip_bg">
        <!-- 用户信息 -->
        <div class="user_box flex-r-sb-c">
          <div class="user_info flex-r-sb-c">
            <div class="user_avater">
              <van-image width="1.33rem" round fit="cover" height="1.33rem"
                         src="https://oss.xiaoyi120.com/shop2.0/my/my_avater_01.png"
              />
              <!-- <van-image v-if="isVip" class="vip_avater" width="1.11rem" round fit="cover" height="0.45rem"
                         :src="`https://oss.xiaoyi120.com/shop2.0/vip/vpi_avater1-${vipIndex*1+1}.png`"
              /> -->
            </div>
            <div class="user_name_kind flex-c-s-s">
              <div class="user_name van-ellipsis" style="text-align: left;">{{ info.name || '优选商城用户' }}</div>
              <div class="user_kind">{{ info.industryName || '宠医 - 医院' }}</div>
            </div>
          </div>
          <div class="user_info_change flex-r-c-c" @click="$router.push('/user/myInfo')">
            <van-icon name="setting-o" color="#0a69ac" />
            <span>完善资料</span>
          </div>
        </div>
        <!-- vip -->
        <div v-if="isVip" class="vip_info flex-r-s-c">
          <van-image width="1.71rem" height="0.48rem"
                     :src="`https://oss.xiaoyi120.com/shop2.0/vip/vip_my01/btn0${vipIndex*1+1}.png`"
          />
          <div class="time">{{ vipInfo.expirationTime }}到期</div>
          <div v-if="vipInfo.discount < 100" class="counts">支付享{{ vipInfo.discount/10 }}折</div>
        </div>
        <div :id="'vipbg' + vipIndex" :class=" isVip ? 'user_vip_box vip_1-4' :'user_vip_box vip_0' ">
          <!-- vip0 -->
          <div v-if="!isVip" class="flex-r-sb-c" style="padding-top: 0.19rem;">
            <div class="vip_box_bg flex-r-s-c">
              <!-- <van-image width="0.64rem" height="0.64rem"
                         src="https://oss.xiaoyi120.com/shop2.0/vip/vip_logo.png"
              /> -->
              <div class="vip_name">商城会员</div>
              <div class="vip_tips">买单折上折 即领即用</div>
            </div>
            <van-image width="1.84rem" height="0.61rem" style="margin-right:0.3rem"
                       src="https://oss.xiaoyi120.com/shop2.0/vip/vip_get.png" @click="getVip"
            />
          </div>
          <!-- vip1-4 -->
          <div v-if="isVip" class="flex-r-sb-c" style="padding-top: 0.19rem;">
            <div class="vip_box_bg flex-r-s-c">
              <!-- <van-image width="5.33rem" height="0.75rem"
                         :src="`https://oss.xiaoyi120.com/shop2.0/vip/vip_logo1-${vipIndex*1+1}.png`"
              /> -->
              <!-- <div :id="'time_0'+(vipIndex*1+1)" class="vip_box_time">{{ vipInfo.expirationTime }}到期</div> -->
              <div :id="'time_0'+(vipIndex*1+1)" class="vip1-4_name">已累计为您节省 <span
                style="font-size:0.43rem;font-weight:bold"
              >{{ vipInfo.accumulatedSaved }}</span> 元</div>
            </div>
            <div :id="'time_0'+(vipIndex*1+1)" style="font-size:0.32rem;margin-right:0.3rem" @click="toVipDetail">查看
              <van-icon name="arrow" />
            </div>
            <!-- <div class="vip_box_info flex-r-sb-c">
              <div :id="'time_0'+(vipIndex*1+1)" class="vip_money">
                <div>累计已省</div>
                <div class="flex-r-s-c" style="margin-top: 0.11rem;">
                  <div class="vip_num">{{ vipInfo.accumulatedSaved }}</div> 元
                </div>
              </div>
              <div>
                <van-image width="2.4rem" height="0.8rem"
                           :src="`https://oss.xiaoyi120.com/shop2.0/vip/vip_btn_1-${vipIndex*1+1}.png`"
                           @click="toVipDetail"
                />
              </div>
            </div> -->
          </div>
          <!-- <div :class="isVip ? 'vip_white vip_white_1-4':'vip_white vip_0'" /> -->
        </div>
        <!-- 订单区域 -->
        <div class="user_order_card">
          <div class="order_card_title flex-r-sb-c">
            <div class="order_card_name">我的订单</div>
            <div class="order_card_tips" @click="jumpOrder('0')">全部订单
              <van-icon name="arrow" />
            </div>
          </div>
          <div class="order_card_item">
            <van-row>
              <van-col v-for="(order,order_index) in userOrderCardList" :key="order_index" :span="order.span"
                       @click="jumpOrder(order.id)"
              >
                <van-badge v-if="order_index < 3" :content="order.badge === 0 ? null : order.badge"
                           class="my-van-badge"
                >
                  <van-image width="1.6rem" height="1.6rem" :src="order.img" />
                </van-badge>
                <div v-else class="my-van-badge">
                  <van-image width="1.6rem" height="1.6rem" :src="order.img" />
                </div>
                <div class="item_name">{{ order.name }}</div>
              </van-col>
            </van-row>
          </div>
          <div v-if="isorderPay" class="order_card_pay">
            <div class="pay_title flex-r-sb-c">
              <div class="pay_name">等待付款</div>
              <div class="pay_time flex-r-s-s" style="line-height:0.53333rem;margin-right:0.05rem">剩余时间
                <van-count-down :time="orderPay.time" format="mm:ss" style="color:#E2432D;font-size:0.32rem" />
              </div>
            </div>
            <div class="pay_contant flex-r-sb-c">
              <van-image v-if="orderPay.details" width="1.87rem" height="1.39rem" :src="orderPay.details[0].proIcon" />
              <div class="contant_info">
                <div class="info_money">待付款：<span>￥{{ orderPay.orderPrice }}</span></div>
                <div v-if="orderPay.details" class="info_name">{{ orderPay.details[0].proName }}</div>
                <div v-if="orderPay.details" class="info_num">共{{ orderPay.details.length }}件商品</div>
              </div>
              <div class="contant_btn">
                <van-button round color="#D1531C" @click="$router.push('/order/orderDetail?id=' +orderPay.orderId )">去付款
                </van-button>
              </div>
            </div>
          </div>
        </div>
        <!-- 推荐 -->
        <div class="user_recommnd flex-r-sa-c">
          <div class="user_recommnd_item flex-r-c-c">
            <van-image width="4.59rem" height="4.05rem;"
                       src="https://oss.xiaoyi120.com/shop2.0/my/my_card_04.png"
                       @click="jumpLive"
            />
          </div>
          <div class="user_recommnd_item flex-c-c-c">
            <div class="user_recommnd_item_1 flex-c-s-s" @click="packetJump(0)">
              <div class="num"><span style="font-size: 0.4rem;font-weight:bold">{{ couponsLenght }}</span> 张</div>
              <div class="tips">我的优惠券</div>
            </div>
            <van-image width="4.37rem" height="1.92rem;" style="margin-top:0.2rem"
                       src="https://oss.xiaoyi120.com/shop2.0/my/my_card_06_1.png"
                       @click="packetJump(4)"
            />
          </div>
        </div>
        <!-- 我的账户 -->
        <div class="user_my_packet">
          <div class="packet_title">我的账户</div>
          <div class="packet_item">
            <van-row>
              <!-- <van-col v-for="(packet,packet_index) in userPacket" :key="packet_index" :span="packet.span"
                       @click="packetJump(packet_index)"
              >
                <van-image width="0.64rem" height="0.64rem" :src="packet.img" />
                <div class="item_name">{{ packet.name }}</div>
              </van-col> -->

              <van-col span="6">
                <wx-open-launch-weapp id="launch-btn" @click="packetJump(1)">
                  <script type="text/wxtag-template">
                    <style>
                    .open_img {width: 26px;height: 26px;display: block;margin-top:30px;margin-left:35px}
                    .open_name {width: 100%;font-size: 13px;color: #333;text-align: center;display: block;margin-top:8px }
                    </style>
                    <image class="open_img" src="https://oss.xiaoyi120.com/shop2.0/my/user03.png" />
                    <div class="open_name">收货地址</div>
                  </script>
                </wx-open-launch-weapp>
              </van-col>
              <van-col span="6">
                <wx-open-launch-weapp id="launch-btn" @click="packetJump(2)">
                  <script type="text/wxtag-template">
                    <style>
                    .open_img {width: 26px;height: 26px;display: block;margin-top:30px;margin-left:35px}
                    .open_name {width: 100%;font-size: 13px;color: #333;text-align: center;display: block;margin-top:8px }
                    </style>
                    <image class="open_img" src="https://oss.xiaoyi120.com/shop2.0/my/user04.png" />
                    <div class="open_name">我的收藏</div>
                  </script>
                </wx-open-launch-weapp>
              </van-col>
              <van-col span="6">
                <wx-open-launch-weapp id="launch-btn" @click="packetJump(3)">
                  <script type="text/wxtag-template">
                    <style>
                    .open_img {width: 26px;height: 26px;display: block;margin-top:30px;margin-left:35px}
                    .open_name {width: 100%;font-size: 13px;color: #333;text-align: center;display: block;margin-top:8px }
                    </style>
                    <image class="open_img" src="https://oss.xiaoyi120.com/shop2.0/my/user06.png" />
                    <div class="open_name">客服</div>
                  </script>
                </wx-open-launch-weapp>
              </van-col>
              <van-col span="6">
                <wx-open-launch-weapp id="launch-btn" appid="wx7fbfea7e8b3f16b3" username="gh_fb8ca52970b0"
                                      path="pages/index/myDevice/myDevice"
                >
                  <script type="text/wxtag-template">
                    <style>
                    .open_img {width: 26px;height: 26px;display: block;margin-top:30px;margin-left:35px}
                    .open_name {width: 100%;font-size: 13px;color: #333;text-align: center;display: block;margin-top:8px }
                    </style>
                    <image class="open_img" src="https://oss.xiaoyi120.com/shop2.0/my/user12.png" />
                    <div class="open_name">我的设备</div>
                  </script>
                </wx-open-launch-weapp>
              </van-col>
              <van-col span="6">
                <wx-open-launch-weapp id="launch-btn" appid="wx7fbfea7e8b3f16b3" username="gh_fb8ca52970b0"
                                      path="pages/linkPage/frontBankend/frontBankend?type=1"
                >
                  <script type="text/wxtag-template">
                    <style>
                    .open_img {width: 26px;height: 26px;display: block;margin-top:30px;margin-left:35px}
                    .open_name {width: 100%;font-size: 13px;color: #333;text-align: center;display: block;margin-top:8px }
                    </style>
                    <image class="open_img" src="https://oss.xiaoyi120.com/shop2.0/my/user10.png" />
                    <div class="open_name">我的前店</div>
                  </script>
                </wx-open-launch-weapp>
              </van-col>
              <van-col span="6">
                <wx-open-launch-weapp id="launch-btn" appid="wx7fbfea7e8b3f16b3" username="gh_fb8ca52970b0"
                                      path="pages/linkPage/frontBankend/frontBankend?type=2"
                >
                  <script type="text/wxtag-template">
                    <style>
                    .open_img {width: 26px;height: 26px;display: block;margin-top:30px;margin-left:35px}
                    .open_name {width: 100%;font-size: 13px;color: #333;text-align: center;display: block;margin-top:8px }
                    </style>
                    <image class="open_img" src="https://oss.xiaoyi120.com/shop2.0/my/user11.png" />
                    <div class="open_name">我的后仓</div>
                  </script>
                </wx-open-launch-weapp>
              </van-col>
            </van-row>
          </div>
        </div>

        <!-- 退出 -->
        <div class="user_out_login">
          <van-button class="out_login_btn" round color="#0767AB" @click="loginOut">退出登录</van-button>
          <div class="versionmy">兽医首选 • 优选商城 {{ version }}</div>
        </div>
      </div>
    </van-skeleton>
    <!-- 版本号 -->
    <!-- Tabber -->
    <Tabber ref="tabber" :index="4" />
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />
    <!-- vip到期提醒 -->
    <!-- 资质认证弹窗 -->
    <van-dialog v-model="vipDialog" :show-confirm-button="false" style="background:none">
      <div class="vipDialog_box flex-c-c-c">
        <div class="vipDialog_title">Hi，{{ vipDialogInfo.companyName }}</div>
        <div class="vipDialog_msg">您的商城权益还有 <span style="color: #C2460E;">{{ vipDialogInfo.daysRemaining }}</span> 天到期
        </div>
        <div class="vipDialog_time">{{ vipDialogInfo.monthStart }}-{{ vipDialogInfo.monthEnd }}</div>
        <div class="vipDialog_dec">权益等级说明</div>
        <div class="vipDialog_info">权益到期后，将根据您当月在兽医首选商城、前店后仓或购买贝思倍健的消费额，重新评定会员等级，次月1号生效。</div>
        <div class="vipDialog_btn" @click="vipDialog = false">知道了</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
const wx = require('jweixin-module')
import './index.scss'
import Tabber from '@/components/tabber'
import kefuBtn from '@/components/kefu_btn'
import { userOrderCard, userPacket } from '@/utils/constants'
import { user_userInfo, user_order_statistics, kickOutByToken, user_coupons } from '@/api/user'
import { member, vipLevelAll, receiveAutomatic, vipPopUpDowngrading } from '@/api/vip'
import { mallOrder_buyer_list } from '@/api/order'
import { mallSaleModule_imageList } from '@/api/home'
import { getJSSDKSign } from '@/api/public'
export default {
  name: 'My',
  components: { Tabber, kefuBtn },
  data() {
    return {
      version: localStorage.getItem('vueVersion'),
      loading: false,
      info: {
        avatar: 'https://oss.xiaoyi120.com/shop2.0/login/login_logo.png',
        name: '兽医首选用户',
        industryName: '医院-宠医'
      },
      vipInfo: {},
      isVip: true,
      vipIndex: null,
      userOrderCard: userOrderCard,
      userOrderCardList: [],
      userPacket: userPacket,
      orderPay: {},
      isorderPay: false,
      fastCellList: [
        {
          iconUrl: 'https://oss.xiaoyi120.com/shop2.0/my/my_sever_01.png',
          name: '邀请有礼', tip: '邀多赠多 好礼叠加'
        },
        {
          iconUrl: 'https://oss.xiaoyi120.com/shop2.0/my/my_sever_02.png',
          name: '联系客服', tip: '专业客服  为您服务'
        },
        // {
        //   iconUrl: 'https://oss.xiaoyi120.com/shop2.0/my/my_sever_03.png',
        //   name: '我的经销商', tip: '专业客服  为您服务'
        // },
        {
          iconUrl: 'https://oss.xiaoyi120.com/shop2.0/my/my_sever_04.png',
          name: '关于我们', tip: '企业简介  业务介绍'
        }
      ],
      inviteCode: '',
      // vipDialog
      vipDialog: false,
      vipDialogInfo: {},
      couponsLenght: 0
    }
  },
  mounted() {
    this.weixinConfig()
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      sessionStorage.setItem('lasterRouter', from.path)
    })
  },
  created() {
    this.$utils.cartNum(this.$store)
    this.getInfo()
  },
  methods: {
    weixinConfig() {
      var localUrl = location.href.split('#')[0]
      var url = encodeURIComponent(localUrl)
      getJSSDKSign(url).then(sign => {
        const payinfo = sign.data
        payinfo['jsApiList'] = ['checkJsApi', 'wx-open-subscribe', 'wx-open-launch-weapp', 'updateTimelineShareData', 'chooseImage', 'previewImage']
        payinfo['openTagList'] = ['wx-open-launch-weapp']
        wx.config(payinfo)
        console.log('wx.weixinConfig', payinfo)
        wx.ready(function () {
          console.log('wx.weixinConfig--002')
        })
        wx.error(function (res) {
          console.log('res weixinConfig', res)
        })
      })
    },
    getInfo() {
      this.loading = true
      mallOrder_buyer_list({ page: 1, limit: 1, status: '1' }).then(res => {
        if (res.data.list[0].expireDate) {
          var start = new Date().getTime()
          var end = new Date(res.data.list[0].expireDate.replace(/-/g, '/')).getTime()
          this.$set(res.data.list[0], 'time', end - start)
          this.orderPay = res.data.list[0]
          this.isorderPay = true
        }
      })
      user_userInfo().then(res => { this.info = res.data })
      // user_inviteCode().then(res => { this.inviteCode = res.data.inviteCode })
      user_order_statistics().then(res => {
        this.userOrderCard[0].badge = res.data.repayCount
        this.userOrderCard[1].badge = res.data.reSendCount
        this.userOrderCard[2].badge = res.data.alSendCount
        this.userOrderCard[3].badge = res.data.finishCount
        this.userOrderCard[4].badge = res.data.refundCount
        console.log('this.userOrderCard', this.userOrderCard)
        this.userOrderCardList = this.userOrderCard
        this.loading = false
      })
      vipLevelAll().then(res => {
        member().then(resx => {
          for (var i = 0; i < res.data.list.length; i++) {
            if (res.data.list[i].id === resx.data.mallVipLevelId) {
              this.vipIndex = i
            }
          }
          this.isVip = resx.data.expirationTime !== null
          this.vipInfo = resx.data
        })
      })
      vipPopUpDowngrading().then(res => {
        this.vipDialogInfo = res.data
        if (res.data.popUpStatus === 1) { this.vipDialog = true }
      })
      user_coupons({ pageNum: 1, pageSize: 999, status: 1 }).then(res => { this.couponsLenght = parseInt(res.data.total) })
      mallSaleModule_imageList({ pageNum: 1, pageSize: 9999, mallSaleModuleId: '1825800588730961920' }).then(res => { this.url = res.data.data[0].imageValue })
    },
    packetJump(index) {
      if (index === 0) { this.$router.push('/user/coupon?isCanAdd=true') }
      if (index === 1) { this.$router.push('/user/addressList') }
      if (index === 2) { this.$router.push('/user/favor') }
      if (index === 3) { this.openKeFu() }
      if (index === 4) { window.open(this.url) }
      // if (index === 1) { this.$toast({ message: '努力开发中...', duration: 2 * 1000 }) }
    },
    // 领取会员
    getVip() {
      receiveAutomatic().then(res => {
        this.getInfo()
        this.$toast({ message: '领取成功！', duration: 1 * 1000 })
      })
    },
    loginOut() {
      kickOutByToken().then(res => { this.$utils.localLogout(this) })
    },
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) },
    jumpOrder(id) {
      if (parseInt(id) === 6) {
        this.$router.push('/order/orderReturnList?status=' + id)
      } else {
        sessionStorage.setItem('ordeListStatus', id)
        this.$router.push('/order/orderList')
      }
    },
    // vip详情
    toVipDetail() { this.$router.push('/user/vipDetail') },
    jumpLive() { this.$router.push(`/product/recommendProList`); sessionStorage.setItem('listJump', 0) }

  }
}
</script>
<style lang="scss">
.my-van-badge {
  .van-badge--fixed {
    top: 0.35rem;
    right: 0.1rem;
  }
}
#launch-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80px;
  width: 100%;
  margin-bottom: 50px;
}
.open_img {
  width: 26px;
  height: 26px;
  display: block;
  margin-top: 30px;
  margin-left: 35px;
}
.open_name {
  width: 100%;
  font-size: 13px;
  color: #333;
  text-align: center;
  display: block;
  margin-top: 8px;
}
</style>
